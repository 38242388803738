import { useAtom } from "jotai";
import { useEffect } from "react";
import { stateAtom } from "~/components/layout/StateBanner";
import type { State } from "~/sdk/qs1/index.server";

/**
 * Will set the state banner atom
 *
 * @param loaderData loaderData containing the state of the session
 */
export const useStateBanner = (
  loaderData?: {
    state: State;
  } & Record<string, any>,
): void => {
  const [, setState] = useAtom(stateAtom);

  useEffect(() => {
    if (!loaderData) return;

    // @ts-ignore: Something weird regarding atom causes this to
    // show an error
    setState(loaderData.state);
  }, [setState, loaderData]);
};
